<template>
  <div>
    <v-container fluid fill-height>
      <c-dashboard-nav v-if="$store.getters['auth/authenticated']"></c-dashboard-nav>
      <v-row class="align-self-center my-6 formCenter">
        <v-col class="pa-0">
          <v-card flat class="text-center pa-2">
            <c-page-heading
              :heading="$t('errors.oops')"
              :subHeading="$t('errors.anErrorOccurred')"
            ></c-page-heading>
            <v-card-text>
              <router-link to="/" class="text-decoration-none">
                <h1>Click here to go back to the main page</h1>
              </router-link>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row class="justify-center">
        <v-col cols="12" :sm="8" :md="6" class="my-3 text-center">
          <div class="login-card">
            <h1 class="error-code">{{ this.status }}</h1>

            <div class="legacy-input-title">
              <h3
                class="font-weight-light"
                data-cy="pleaseTryAgainOrContactSupportIfThisProblemPersists"
              >
                {{ $t('errors.pleaseTryAgainOrContactSupportIfThisProblemPersists') }}
              </h3>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  computed: {
    status() {
      return this.$route.params.status
    },
  },
}
</script>
<style scoped>
.error-code {
  font-size: 8rem;
  font-weight: 500;
}
</style>
